import React, { useEffect } from "react";

const Redirect = ({ links }) => {
  useEffect(() => {
    const userAgent = navigator.userAgent;
    const isIPod = userAgent.includes("iPod");
    const isIPhone = userAgent.includes("iPhone");
    const isIPad = userAgent.includes("iPad");
    const isAndroid = userAgent.includes("Android");
    const isWebOS = userAgent.includes("webOS");

    // Выполняем редирект в зависимости от типа устройства
    if (isIPad || isIPhone || isIPod) {
      window.location.href = links.ios;
    } else if (isAndroid) {
      window.location.href = links.android;
    } else if (isWebOS) {
      console.log("WebOS");
    } else {
      window.location.href = links.desk;
    }
  }, []);
  return <div></div>;
};

export default Redirect;
