import { BrowserRouter, Route, Routes } from "react-router-dom";
import Redirect from "./redirects/Redirect";

const CASES = [
  {
    title: "PublicArt",
    path: "PublicARt/redir.php",
    links: {
      ios: "https://apps.apple.com/us/app/public-art/id1517650290",
      android: "https://play.google.com/store/apps/details?id=ru.pushkeen.publicart",
      desk: null,
    },
  },
  {
    title: "Мой Московский",
    path: "moymoskovsky/redir.php",
    links: {
      ios: "https://apps.apple.com/app/id1569795532",
      android: "https://play.google.com/store/apps/details?id=com.pushkeen.moskovsky",
      desk: null,
    },
  },
  {
    title: "РЖД",
    path: "RZD",
    links: {
      ios: "https://apps.apple.com/ru/app/traindetective-visual-novel/id6470943390",
      android: "https://play.google.com/store/apps/details?id=com.pushkeen.traindetective",
      desk: null,
    },
  },
  {
    title: "Nevsky Runner",
    path: "nevsky-runner",
    links: {
      ios: "https://apps.apple.com/ru/app/nevsky-runner/id6478032970",
      android: "https://play.google.com/store/apps/details?id=com.pushkeen.nevskyrunner",
      desk: "https://nevsky-runner.pushkeen.ru/",
    },
  },
  {
    title: "Arka",
    path: "arka",
    links: {
      ios: "https://apps.apple.com/ru/app/arka/id6449153717?l=en-GB",
      android: "https://play.google.com/store/apps/details?id=com.pushkeen.arka",
      desk: null,
    },
  },
];

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/file">
          <Route
            index
            element={<Redirect links={CASES.find((e) => e.title === "PublicArt").links} />}
          />
          {CASES.map((currentCase) => {
            return (
              <Route
                path={currentCase.path}
                element={<Redirect links={currentCase.links} />}
              />
            );
          })}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
